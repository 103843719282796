import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Header from '../components/Header';
import '../styles/Contact.css';

const Contactpage = () => {
  return (
    <>
      <Header />
      <Contact />
      <Footer />
    </>
  );
};
export default Contactpage;
