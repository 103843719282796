import AcademyHeader from '../components/AcademyHeader';
import Footer from '../components/Footer';
import Ppl from '../components/Ppl';
import '../styles/Course.css';

const Pplpage = () => {
  return (
    <>
      <AcademyHeader />
      <Ppl />
      <Footer />
    </>
  );
};
export default Pplpage;
