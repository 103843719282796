import AcademyHeader from '../components/AcademyHeader';
import Atpl from '../components/Atpl';
import Footer from '../components/Footer';
import '../styles/Course.css';

const Atplpage = () => {
  return (
    <>
      <AcademyHeader />
      <Atpl />
      <Footer />
    </>
  );
};
export default Atplpage;
